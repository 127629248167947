@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

@layer base{
    *{
        @apply font-[Raleway]
    }
}
/* Dark */
:root{
    --background:10, 25, 47;
    --shadow:4,12,122;
    --secondary_background:31, 40, 51;
    --border_normal:197,198,199;
    --border_highlighter:102,252,241;
    --text_navbar: 209, 213, 219;
    --text_secondary:136,146,176;
    --text_heading: 204,214,246;
    --text_highlighter: 102, 252, 241;
    --hover:69,162,158;
}
/* Light */
/* :root {
    --background: 209, 213, 219;
    --shadow: 168, 180, 204;
    --secondary_background: 225, 230, 236;
    --border_normal: 150, 150, 150;
    --border_highlighter: 0, 0, 0;
    --text_navbar: 30, 30, 30;
    --text_secondary: 80, 100, 130;
    --text_heading: 60, 70, 100;
    --text_highlighter: 0, 255, 255;
    --hover: 100, 200, 200;
} */